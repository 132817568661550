import { useCallback, useContext, useEffect } from 'react';
import React from 'react';
import { CurrentUserContext, IUser } from './userContext';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { addNotification } from './notifications';

const connection = new HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_PUBLIC_API_URL}/hubs/notifications`)
  .build();

connection.on('Notify', ({text, type }) => {
  addNotification(text, type);
});

export const useRecheckAuth = () => {
  const userContext = useContext(CurrentUserContext);
  const getClaims = useGetClaims();

  return useCallback(() => fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/poll`, { credentials: 'include' })
    .then(res => {
      if (!res.ok) {
        if (userContext.currentUser?.isLogged || userContext.currentUser?.loading) {
          userContext.setCurrentUser({user: null, isLogged: false, loading: false});
        }
        try {
          connection.stop();
        } catch {
        }
      } else {
        return res.text()
          .then((unique_name: string) => {
            if (!userContext.currentUser?.isLogged
                || userContext.currentUser?.user?.unique_name !== unique_name
                || !(connection.state === HubConnectionState.Connected || connection.state === HubConnectionState.Connecting)) {
              getClaims();
            }
        });
      }
    }), [userContext.currentUser, getClaims]);
};

const useGetClaims = () => {
  const userContext = useContext(CurrentUserContext);
  return useCallback(() => fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/users/claims`, { credentials: 'include' })
    .then(res => {
      if(res.ok) {
        if (!(connection.state === HubConnectionState.Connected || connection.state === HubConnectionState.Connecting))
          connection.start();

        connection.onclose(async () => {
          if (userContext.currentUser?.isLogged) {
            const intervalId = setInterval(async () => {
              if (connection.state !== HubConnectionState.Connected && connection.state !== HubConnectionState.Connecting) {
                try {
                  await connection.start();
                } catch (err) {
                  console.log(err);
                }
              } else if (connection.state === HubConnectionState.Connected) {
                clearInterval(intervalId);
              }
            }, 200);
          }
        });

        return res.json().then((userData) => {    
          const user: IUser = {} as IUser;
          if (userData?.length) {
            userData.forEach((key: { key: keyof IUser; value: string; }) => {
              user[key.key] = key.value;
            });
          }

          userContext.setCurrentUser({isLogged: true, loading: false, user});
        });
    }
  }), [userContext.currentUser]);
};

export const CheckAuth = () => {
    const recheckAuth = useRecheckAuth();

    useEffect(() => {
      const intervalTime = 5000;
      const intervalId = setInterval(recheckAuth, intervalTime);
      return () => clearInterval(intervalId);
    }, [recheckAuth]);

    return (<></>);
};