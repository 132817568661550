import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

const Config = () => {};

Config.initialize = () => {
  Auth.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
    },
  });

  API.configure({
    API: {
      endpoints: [
        {
          name: 'pam-backend',
          endpoint: '/api/v1/',
          custom_header: async () => {
            const session = await Auth.currentSession();

            return {
              Authorization: `${session.getIdToken().getJwtToken()}`,
            };
          },
        },
      ],
    },
  });
};

export default Config;
