import React, { useState } from 'react';
import { SWRConfig } from 'swr';
import Notifications from 'components/Notifications';
import Routes from 'routes';
import { GlobalStyles } from 'styles/theme';
import Loader from './components/Loader';
import { ICurrentUser, CurrentUserContext } from 'utils/userContext';
import { CheckAuth } from 'utils/checkAuth';

const App = () => {
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({ isLogged: false, loading: true, user: null});

  return (
    <>
      <CurrentUserContext.Provider value = { { currentUser, setCurrentUser } }>
        <CheckAuth />
        <GlobalStyles />
        <Notifications />
        <SWRConfig value={{ shouldRetryOnError: false, dedupingInterval: 20000 }}>
          {currentUser.loading ?  <><Loader /></> : <><Routes /> </>}
        </SWRConfig>
      </CurrentUserContext.Provider>
    </>
  );
};

export default App;
