export const safePromise = async <T extends unknown>(
  promise: Promise<T>
): Promise<[any | null, T | null]> => {
  try {
    const result: T = await promise;
    return [null, result];
  } catch (error) {
    return [error, null];
  }
};

export const isEmpty = (obj: Record<string, any>): boolean =>
  Object.keys(obj).length === 0;

export const toTitleCase = (s: string): string =>
  s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

export const extractInitials = (name: string): string => {
  const [firstName, lastName] = name
    .split(' ')
    .map((namePart) => namePart.trim());

  if (lastName && lastName.length > 0) {
    if (!firstName || firstName.length === 0) {
      if (lastName.length > 1) {
        return lastName.substring(0, 2);
      }

      if (lastName.length === 1) {
        return `${lastName}${lastName}`;
      }

      return 'XX';
    }

    return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;
  }

  if (firstName) {
    if (firstName.length > 1) {
      return firstName.substring(0, 2);
    }

    if (firstName.length === 1) {
      return `${firstName}${firstName}`;
    }
  }

  return 'XX';
};

const tester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const validateEamil = (email: string): boolean => {
  if (!email) {
    return false;
  }

  if (email.length > 256) {
    return false;
  }

  if (!tester.test(email)) {
    return false;
  }

  // Further checking of some things regex can't handle
  const [account, address] = email.split('@');
  if (account.length > 64) {
    return false;
  }

  const domainParts = address.split('.');

  if (domainParts.some((part) => part.length > 63)) {
    return false;
  }

  return true;
};

export const upsertItem = <Item extends { id: string }>(
  item: Item | Item[],
  items: Item[] | undefined = []
): Item[] => {
  if (!Array.isArray(item)) {
    const newItems = [...items];

    const indexOfItem = items.findIndex(({ id }) => id === item.id);

    if (indexOfItem === -1) {
      return [item, ...items];
    }

    newItems.splice(indexOfItem, 1, item);

    return newItems;
  } else {
    if (item.length === 0) {
      return items;
    }

    const [first, ...rest] = item;

    if (rest.length === 0) {
      return upsertItem(first, items);
    }

    return upsertItem(rest, upsertItem(first, items));
  }
};

export const removeItem = <Item extends { id: string }>(
  item: Item | Item[],
  items: Item[] | undefined
): Item[] => {
  if (items) {
    let ids: string[];

    if (Array.isArray(item)) {
      ids = item.map(({ id }) => id);
    } else {
      ids = [item.id];
    }

    return items.filter(({ id }) => !ids.includes(id));
  }

  return [];
};

export const alterItemAt = <
  Item extends { id: string; [key: string]: ChildItem[] | any },
  ChildItem extends { id: string }
>(
  items: Item[] | undefined,
  itemId: string,
  key: string,
  childItem: ChildItem,
  remove = false
): Item[] | undefined => {
  if (!items) {
    return items;
  }

  const foundIndex = items.findIndex(({ id }) => id === itemId);

  if (foundIndex < 0) {
    return items;
  }

  const newItems = [...items];
  const found = items[foundIndex];
  const children: ChildItem[] = found[key] || [];
  let newChildren: ChildItem[];

  if (remove) {
    newChildren = children.filter(({ id }) => id !== childItem.id);
  } else {
    const childIndex = children.findIndex(({ id }) => id === childItem.id);

    if (childIndex > -1) {
      newChildren = [...children];
      newChildren.splice(childIndex, 1, childItem);
    } else {
      newChildren = [...children, childItem];
    }
  }

  const updated = {
    ...found,
    [key]: newChildren,
  };

  newItems.splice(foundIndex, 1, updated);

  return newItems;
};

export const parseBase36String = (
  base36String?: string
): number | undefined => {
  if (!base36String) {
    return undefined;
  }

  try {
    return parseInt(base36String, 36);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not parse base36 string.', error);
    return undefined;
  }
};
