import React from 'react';
import styled from 'styled-components';

import Portal from 'components/Portal';

import loader from 'assets/images/loader.gif';
import theme from 'styles/theme';

const LargeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${theme.colors.offWhite}};
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 40;
`;

const SmallContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface Props {
  fullScreen?: boolean;
}
const Loader = ({
  fullScreen = true,
}: Props) => {
  return (
    <Portal id="loader-portal">
      {fullScreen 
      ?
      <LargeContainer>
        <img src={loader} width="200" alt="Loader" />
      </LargeContainer>
      :
      <SmallContainer>
        <img src={loader} width="150" alt="Loader" />
      </SmallContainer>
      }
    </Portal>
  );
};

export default Loader;
