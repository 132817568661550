import { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { NotificationItem, NotificationType } from 'utils/types';

const notifications: NotificationItem[] = [];

export const addNotification = (
  text: string,
  type: NotificationType = 'error',
  showOnLoginScreen: boolean = false,
): void => {
  notifications.push({
    key: uuidv4(),
    text,
    type,
    showOnLoginScreen,
  });

  mutate('notifications');
};

export const removeNotification = (itemKey: string): void => {
  const index = notifications.findIndex(({ key }) => key === itemKey);

  if (index > -1) {
    notifications.splice(index, 1);

    mutate('notifications');
  }
};

export const getNotifications = (): NotificationItem[] => {
  return [...notifications];
};
