import React, { Suspense, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from 'components/CustomRoute';
import Loader from 'components/Loader';

const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));

const Router = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/login" type="public">
          <Auth />
        </Route>
        <Route path="/" type="private">
          <Home />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
};

export default Router;
