import { useEffect, useRef, useState, useCallback } from 'react';
import useSWR, { keyInterface, mutate, ConfigInterface } from 'swr';

import { validateEamil, removeItem, upsertItem } from 'utils';
import { NotificationItem } from 'utils/types';
import { addNotification, getNotifications } from 'utils/notifications';
import { useRecheckAuth } from 'utils/checkAuth';

export interface APIOptions {
  body?: Record<string, any> | string;
  headers?: Record<string, string>;
}

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem: Element) => {
  if (document.body && document.body.lastElementChild) {
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling
    );
  }
};

export const usePortal = (id: string) => {
  const rootElemRef: { current: null | HTMLElement } = useRef(null);

  const getRootElemRef = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  useEffect(() => {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${id}`);
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(id);

    // If there is no existing DOM element, add a new one.
    if (!existingParent) {
      addRootElement(parentElem);
    }

    // Add the detached element to the parent
    if (rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }

    return function removeElement() {
      if (rootElemRef.current) {
        rootElemRef.current.remove();
      }
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, [id]);

  return getRootElemRef();
};

export const usePageVisibility = (): boolean => {
  const [visible, setVisible] = useState(!document.hidden);

  useEffect(() => {
    const onChange = (): void => {
      setVisible(document.visibilityState !== 'hidden');
    };

    document.addEventListener('visibilitychange', onChange);

    return (): void => {
      document.removeEventListener('visibilitychange', onChange);
    };
  }, []);

  return visible;
};

const toUrlRegEpx = /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]+((:(\d+)?)?[\/\?][^\s]*)?COUNTRY([^\s]*)$/;
const toStringRegEpx = /^[a-z]+$/i;
const toNumberRegEpx = /^\d+$/;
const toCountryRegEpx = /country/i;

let pairedFieldsData:{[key:string]: {[key:string]:string}} = {};

const formValidator = (form: Form, item: ValidatorItem): boolean => {
  if (item.validator) {
    return item.validator(form, item);
  }
  const { minLength, maxLength, value, required, type, validationPattern, pairRequired, name } = item;
  const valueTrimed = value.trim();

  if (valueTrimed !== '' && validationPattern) {
    if (validationPattern === 'country') {
      return toCountryRegEpx.test(valueTrimed);
    } else if (validationPattern === 'url') {
      return toUrlRegEpx.test(valueTrimed);
    } else if (validationPattern === 'number') {
      return toNumberRegEpx.test(valueTrimed);
    } else if (validationPattern === 'string') {
      return toStringRegEpx.test(valueTrimed);
    } else if (validationPattern instanceof RegExp) {
      return validationPattern.test(valueTrimed);
    }
  }

  if (minLength && valueTrimed.length < minLength) {
    return false;
  }

  if (maxLength && valueTrimed.length > maxLength) {
    return false;
  }
  if (required) {
    if (pairRequired) {
      pairedFieldsData = {
        ...pairedFieldsData,
        [pairRequired]: {
          ...pairedFieldsData[pairRequired],
          [name]: valueTrimed,
        }
      };

      return Object.values(pairedFieldsData[pairRequired]).filter((value: any) => value !== '').length > 0;
    }

    if (type === 'email') {
      return validateEamil(valueTrimed);
    }

    if (type === 'checkbox' && valueTrimed !== 'true') {
      return false;
    }

    if (valueTrimed === '') {
      return false;
    }
  }

  return true;
};

const variablesToFormObject = (
  variables: FormVariable[],
  initialValues: FormValues = {},
  initialForm: Form = {}
): Form => {
  const form: Form = {};

  for (let i = 0; i < variables.length; i += 1) {
    const variable = variables[i];
    const formVariable = initialForm[variable.name];

    let validatorItem;

    if (formVariable) {
      validatorItem = {
        ...variable,
        value: formVariable.value,
      };
    } else {
      const initialValue = initialValues[variable.name];

      validatorItem = {
        ...variable,
        value: initialValue || '',
      };
    }

    const isValid = formValidator({}, validatorItem);

    form[variable.name] = {
      ...validatorItem,
      isValid,
    };
  }

  return form;
};

const isFormValid = (form: Form): boolean => {
  const fields = Object.keys(form);

  return fields.reduce((previousValue: boolean, key) => {
    // check if field is required and has pair
    if (form[key].pairRequired) {
      // other paired field index
      const pairIndex = fields.findIndex(field => field !== form[key].name && form[field].pairRequired && form[field].pairRequired === form[key].pairRequired);
      if (pairIndex !== -1 && (form[key].value || form[fields[pairIndex]].value) && form[key].pairRequired === form[fields[pairIndex]].pairRequired) {
        return previousValue;
      } else {
        return false;
      }
    }

    return previousValue && form[key].isValid;
  }, true);
};

export const useForm = (
  variables: FormVariable[],
  initialValues: FormValues = {}
): [Form, OnInputChange, boolean, OnItemChange] => {
  const initialForm = variablesToFormObject(variables, initialValues);
  const [form, setForm] = useState(initialForm);
  const [isValid, setValid] = useState(isFormValid(form));

  useEffect(() => {
    setForm((currentForm) => {
      const newForm = variablesToFormObject(
        variables,
        initialValues,
        currentForm
      );

      return newForm;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  useEffect(() => {
    setValid(isFormValid(form));
  }, [form]);

  const onItemChange: OnItemChange = useCallback((name, item) => {
    setForm((current) => {
      if (current[name] !== undefined) {
        const newForm = { ...current };

        const variable = newForm[name];

        if (typeof item === 'string') {
          variable.value = item;
        }

        variable.isValid = formValidator(newForm, variable);

        return newForm;
      }

      console.error(`Invalid form variable: ${name}`); // eslint-disable-line no-console

      return current;
    });
  }, []);

  const onChange: OnInputChange = useCallback((event) => {
    if (event && event.target) {
      const variableName = event.target.name;
      const isCheckBox =
        event.target instanceof HTMLInputElement &&
        event.target.type === 'checkbox';
      const { value } = event.target;
      let checked = false;

      if (
        event.target instanceof HTMLInputElement &&
        event.target.type === 'checkbox'
      ) {
        checked = event.target.checked;
      }

      setForm((current) => {
        if (current[variableName] !== undefined) {
          const newForm = { ...current };

          const variable = newForm[variableName];

          let newValue;

          if (isCheckBox) {
            newValue = `${checked ? 'true' : 'false'}`;
          } else {
            newValue = value;
          }

          if (variable.case) {
            if (variable.case === 'upper') {
              newValue = newValue.toUpperCase();
            } else if (variable.case === 'lower') {
              newValue = newValue.toLowerCase();
            }
          }

          variable.value = newValue;
          variable.isValid = formValidator(newForm, variable);

          return newForm;
        }

        console.error(`Invalid form variable: ${variableName}`); // eslint-disable-line no-console

        return current;
      });
    } else {
      console.error('Invalid form event'); // eslint-disable-line no-console
    }
  }, []);

  return [form, onChange, isValid, onItemChange];
};

export const useNotifications = () => {
  const { data: notifications } = useSWR<NotificationItem[]>(
    'notifications',
    getNotifications
  );

  return notifications;
};

export type ApiMethod = 'get' | 'post' | 'put' | 'delete';

interface ApiOptions {
  method?: ApiMethod;
  request?: APIOptions;
  cache?: ConfigInterface;
}

const extendCacheKey = (cacheKey: string | string[], id: string): string[] => {
  if (typeof cacheKey === 'string') {
    return [cacheKey, id];
  }

  return [...cacheKey, id];
};

export const useApi = <Response extends unknown = any>(
  path: string,
  cacheKey: string | string[] | null,
  options: ApiOptions = {}
): [Response | undefined, RequestError | undefined] => {
  const recheckAuth = useRecheckAuth();
  const { data, error } = useSWR<Response, RequestError>(
    cacheKey,
    async () => {
      const mainPath = `${process.env.REACT_APP_PUBLIC_API_URL}`;
      if (path === null) {
        throw Error('Path cannot be null');
      }
      const method = options.method || 'get';

      let requestData:RequestInit = {
        headers: {
          'Content-Type': 'application/json',
        },
        method,
        credentials: 'include'
      };

      if (options.request) {
        requestData = {
          ...requestData,
          ...options.request,
        } as RequestInit;
      }

      let response;

      switch (method) {
        case 'get':
          response = await fetch(`${mainPath}/${path}`, { credentials: 'include' });
          break;
        case 'post':
        case 'put':
        case 'delete':
          response = await fetch(`${mainPath}/${path}`, requestData);
          break;
        default:
          throw new Error(`Invalid api method: ${method}`);
      }

      if (response.ok) {
        return await response.json().catch(() => undefined);
      } else {
        if (response.status === 401)
          await recheckAuth();

        const errorResponse = await response.json().catch(() => undefined);
        addNotification(
          (Array.isArray(errorResponse) ? errorResponse.join('\n') : errorResponse) || 'Request failed.',
          'error'
        );
        return undefined;
      }
    },
    options.cache
  );

  return [data, error];
};

type MutateCallback<Data = any> = (currentValue: Data | undefined) => Data;
type MutateData<Data> = Data | Promise<Data> | MutateCallback<Data>;
type MutateInterface<Data = any> = (
  key: keyInterface,
  data?: Data | Promise<Data> | MutateCallback<Data>,
  shouldRevalidate?: boolean
) => Promise<Data | undefined>;

interface UpdateOptions<Response, CacheType> {
  method?: ApiMethod;
  request?: APIOptions;
  optimisticUpdateData?: MutateData<CacheType> | object;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: (response?: Response) => void;
  onFailure?: (error?: RequestError) => void;
  onCacheMutate?: (
    result: {
      response?: Response;
      error?: RequestError;
      currentData: CacheType;
    },
    mutateCache: MutateInterface<Response>
  ) => CacheType;
  remove?: boolean;
}

export type UpdateFn<Response> = (
  path: string,
  cacheKey: string | string[],
  options?: UpdateOptions<Response, Response[]>
) => void;

const requestFunction = async <Response extends { id: string }>(method: 'get' | 'post' | 'put' | 'delete',
 path: string, requestData: RequestInit|undefined, options: { errorMessage?: any; }):Promise<[Response | undefined, RequestError | undefined]> => {
  let response: Response | undefined;
  let error: RequestError | undefined;

    switch (method) {
      case 'get':
        let resGetData;
        try {
          resGetData = await fetch(path, { credentials: 'include' });
        } catch (error) {
          return [undefined, { message : (error as Error)?.message || 'Request failed.', response : undefined }];
        }

        if (resGetData.ok) {
          response = await resGetData.json().catch(() => undefined);
        } else {
          response = undefined;
          const errorResponse = await resGetData.json().catch(() => undefined);
          error = { message : Array.isArray(errorResponse) ? errorResponse.join('\n') : errorResponse, response : resGetData };
        }
        break;
      case 'post':
      case 'put':
      case 'delete':
        let resData;
        try {
          resData = await fetch(path, requestData);
        } catch (error) {
          return [undefined, { message : (error as Error)?.message || 'Request failed.', response : undefined }];
        }

        if (resData.ok) {
          response = await resData.json().catch(() => undefined);
        } else {
          response = undefined;
          const errorResponse = await resData.json().catch(() => undefined);
          error = { message : Array.isArray(errorResponse) ? errorResponse.join('\n') : errorResponse, response : resData };
        }
  }
  return [response, error];
};

async function showErrorNotification(errorMessage: string | undefined) {
  addNotification(errorMessage || 'Request failed.', 'error');
}

export const useApiUpdate = <Response extends { id: string }>(): [
  boolean,
  UpdateFn<Response>
] => {
  const [isLoading, setLoading] = useState(false);
  const recheckAuth = useRecheckAuth();

  const updateFn: UpdateFn<Response> = useCallback(
    async (path, cacheKey, options = {}) => {
      setLoading(true);

      const isOptimistic = options.optimisticUpdateData !== undefined;

      const mainPath = `${process.env.REACT_APP_PUBLIC_API_URL}`;
      const method = options.method || 'post';

      let requestData:RequestInit = {
        headers: {},
        method,
        credentials: 'include'
      };

      if (!options.request?.headers) {
        requestData.headers = {
          'Content-Type': 'application/json',
        };
      }

      if (options.request) {
        requestData = {
          ...requestData,
          ...options.request,
        } as RequestInit;
      }

      const mutateCallback = async (
        currentData: Response[] | undefined
      ): Promise<Response[]> => {
        const [ response, error ]:[
          Response | undefined, RequestError | undefined
          ] = await requestFunction(method, `${mainPath}/${path}`, requestData, options);

        let newData: Response[];

        if (options.onCacheMutate) {
          newData = options.onCacheMutate(
            { response, error, currentData: currentData || [] },
            mutate
          );
        } else if (response) {
          mutate(extendCacheKey(cacheKey, response.id), response, false);

          if (
            options.remove === true ||
            (options.remove === undefined && method === 'delete')
          ) {
            newData = removeItem(response, currentData || []);
          } else {
            newData = upsertItem(response, currentData || []);
          }
          // looks like swr with second parameter with function returns fullfiled promise right after call.
          // so it is not working for long time requests.
          // duplicate mutate here, when the request really done, helps to solve this problem.
          mutate(cacheKey, newData, false);
        } else {
          newData = currentData || [];
        }

        const isSuccess = error === undefined;

        if (isSuccess) {
          options.onSuccess?.(response);
        } else {
          if (options.onFailure) {
            options.onFailure(error);
          } else {
            if (error?.response?.status === 401)
              await recheckAuth();

            showErrorNotification(error?.message || options?.errorMessage);
          }
        }

        setLoading(false);
        return newData;
      };

      // optimistic update for cases when we delete an item we receive success without an id of the deleted item.
      if (isOptimistic) {
        const [, error] = await requestFunction(method, `${mainPath}/${path}`, requestData, options);
        if (error === undefined) {
          mutate(cacheKey, options.optimisticUpdateData, false);
          options.onSuccess?.();
        } else {
          if (error?.response?.status === 401)
            await recheckAuth();

          if (options.onFailure) {
            options.onFailure(error);
          } else {
            showErrorNotification(error?.message || options?.errorMessage);
          }
        }

        setLoading(false);
        return;
      }
      mutate(cacheKey, mutateCallback, false);
    },
    []
  );

  return [isLoading, updateFn];
};

export const useCacheMutate = () => {
  return useCallback(
    <T extends { id: string }>(
      cacheKey: string | string[],
      item: T | T[],
      remove: boolean = false
    ) => {
      if (!remove) {
        if (Array.isArray(item)) {
          item.forEach((i) => mutate(extendCacheKey(cacheKey, i.id), i, false));
        } else {
          mutate(extendCacheKey(cacheKey, item.id), item, false);
        }
      }

      mutate(
        cacheKey,
        (items: T[]) => {
          if (remove) {
            return removeItem(item, items);
          } else {
            return upsertItem(item, items);
          }
        },
        false
      );
    },
    []
  );
};

export const useDebounce = (
  fn: Function,
  delay: number
): [Function, () => void] => {
  const timeout = useRef<number | undefined>();

  useEffect(
    () => (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    },
    []
  );

  const cancel = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }, []);

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = window.setTimeout(() => {
        fn(...args);
        timeout.current = undefined;
      }, delay);
    },
    [delay, fn]
  );

  return [debouncedFn, cancel];
};

/* Use this function if you don't know what you are doing.
A more simple version. */
export function useSimpleDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = window.setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useCountries = (): Country[] | undefined | any => {
  const [countries] = useApi<Country[]>('countries', 'countries', {
    cache: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    },
  });
  return countries;
};

export const useLanguages = (): Language[] | undefined => {
  const [languages] = useApi<Language[]>('languages', 'languages', {
    cache: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    },
  });

  return languages;
};

export const useTwitterAdAccounts = (
  shouldFetch = true
): TwitterAdAccount[] | undefined => {
  const [adAccounts] = useApi<TwitterAdAccount[]>(
    'twitter/ad-account',
    shouldFetch ? 'twitter-ad-account' : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return adAccounts;
};

export const useTwitterPromotableUsers = (
  adAccountId?: string
): TwitterPromotableUser[] | undefined => {
  const [promotableUsers] = useApi<TwitterPromotableUser[]>(
    `twitter/ad-account/${adAccountId}/promotable-user`,
    adAccountId ? ['twitter-promotable-user', adAccountId] : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return promotableUsers;
};

export const useTwitterFundingInstruments = (
  adAccountId?: string
): TwitterFundingInstrument[] | undefined => {
  const [fundingInstruments] = useApi<TwitterFundingInstrument[]>(
    `twitter/ad-account/${adAccountId}/funding-instrument`,
    adAccountId ? ['twitter-funding-instrument', adAccountId] : null,
    {
      cache: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 300000,
      },
    }
  );

  return fundingInstruments;
};

export const useCountryGroups = (
  clientId: string,
  suspense = false
): CountryGroup[] | undefined => {
  let cacheOptions;

  if (suspense) {
    cacheOptions = { suspense: true };
  } else {
    cacheOptions = {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 300000,
    };
  }

  const [countryGroups] = useApi<CountryGroup[]>(
    `clients/${clientId}/countryGroups`,
    ['country-group', clientId],
    { cache: cacheOptions }
  );

  return countryGroups;
};

// this hook solves swr issue when it always updates data with backend
// and when response object received then rerender happens
// then some inputed data is lost or modal closed
export const useApiWithState = function<T>(url: string, params: string | string[], options: ApiOptions): [T | undefined, any] {
  const [apiData] = useApi<T>(url, params, options);
  const [state, setState] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (JSON.stringify(apiData) !== JSON.stringify(state)) {
      setState(apiData);
    }
  }, [apiData, state]);

  return [state, setState];
};
