import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from 'utils/hooks';

interface Props {
  id: string;
  children: ReactNode;
}

const Portal = ({ id, children }: Props) => {
  const target = usePortal(id);

  return createPortal(children, target);
};

export default Portal;
