import { createContext } from 'react';

export interface IUser {
    name: string
    unique_name: string
}

export interface ICurrentUser {
    isLogged: boolean
    loading: boolean
    user: IUser | null
}

export interface ICurrentUserContext {
    currentUser : ICurrentUser | undefined;
    setCurrentUser(user: ICurrentUser) : any;
}

export const CurrentUserContext = createContext<ICurrentUserContext>({
    currentUser: undefined,
    setCurrentUser: (user) => {}
});